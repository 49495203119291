<template>
  <div class="appointmentList-view">
    <!-- <div>
      <el-input
          class="w200 mb10 mr10"
          placeholder="请输入员工姓名"
          v-model="serchParams.name"
          clearable
      />
      <el-input
          class="w200 mb10 mr10"
          placeholder="请输入员工手机号"
          v-model="serchParams.phone"
          clearable
      />
      <el-button type="primary" class="myBtn" @click="serchquery">搜索</el-button>
      <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
    </div> -->
    <el-button @click="add" class="el-button ml10 el-button--primary el-button--small" v-if="checkPermission(['prizeEdit'])" style="margin: 0 0 10px 0px;">新增</el-button>

      <div>
        <el-select v-model="queryParam.produceId" placeholder="请选择产品" style="margin-right: 10px;">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>

        <el-select v-model="queryParam.dealerId" placeholder="请选择代理商" v-if="userType == 2">
          <el-option
            v-for="item in dealerOptions"
            :key="item.id"
            :label="item.dealerName"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" class="myBtn" @click="serchquery" style="margin-left: 30px;">搜索</el-button>
        <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
      </div>

    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="queryParam.pageNum"
        :loading="loading"
        :total="total"
        :span-method="objectSpanMethod"
        highlight-current-row="false"
    >
      <template v-slot:table>
        <!--        <el-table-column type="expand">-->
        <!--          <template slot-scope="props">-->
        <!--            <el-form label-position="left" inline class="demo-table-expand" v-for="item in props.row.winningDtos" :key="item.id">-->
        <!--              <el-form-item label="数量">-->
        <!--                <span>{{ item.num }}</span>-->
        <!--              </el-form-item>-->
        <!--              <el-form-item label="奖品">-->
        <!--                <span>{{ item.prize }}</span>-->
        <!--              </el-form-item>-->
        <!--              <el-form-item label="描述">-->
        <!--                <span>{{ item.intro }}</span>-->
        <!--              </el-form-item>-->
        <!--            </el-form>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column prop="dealerCode" align="center" label="代理商" v-if="userType == 2"></el-table-column>
        <el-table-column prop="produceName" align="center" label="产品"></el-table-column>
        <el-table-column prop="startTime" align="center" label="开始时间"></el-table-column>
        <el-table-column prop="endTime" align="center" label="结束时间"></el-table-column>
        <el-table-column prop="ranking" align="center" label="奖项">
          <template slot-scope="scope">
            {{scope.row.ranking | getWinningType}}
          </template>
        </el-table-column>
        <el-table-column prop="num" align="center" label="数量"></el-table-column>
        <el-table-column prop="prize" align="center" label="奖品"></el-table-column>
        <el-table-column prop="intro" align="center" label="描述"></el-table-column>
        <el-table-column label="操作" align="center" v-if="checkPermission(['prizeEdit'])">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <!-- <el-button type="text" @click="del(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :visible.sync="addDialogShow" append-to-body :title="dialogTit" :close-on-click-modal="false">
      <el-form label-width="120px" :model="formData" :rules="rules" ref="form">
        <el-form-item label="产品" prop="produceId">
          <el-select v-model="formData.produceId" placeholder="请选择" @change="check">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <p v-if="produceNum >0" style="margin-bottom: 10px;margin-left: 13%">该产品每箱共计<span style="color: red;font-size: 14px;font-weight: bold">{{produceNum}}件</span>单品</p>
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
              v-model="formData.datetimerange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期" @change="dateChange"
              :picker-options="pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <div v-for="(domain, index) in formData.winnings" :key="domain.key" class="domainBox">
          <div class="domainLeft">
            <el-form-item
                :label="'奖项'"
            >
              <div>{{ numberToChinese(index + 1) }}等奖</div>
            </el-form-item>

            <el-form-item
                :label="'奖品'"
                :prop="'winnings.' + index + '.prize'"
                :rules="{
                    required: true, message: '请输入奖品', trigger: 'blur'
                  }"
            >
              <el-input v-model="domain.prize"></el-input>
            </el-form-item>

            <el-form-item
                :label="'单箱奖品数量'"
                :prop="'winnings.' + index + '.num'"
                :rules="{
                    required: true, message: '数量不能为空', trigger: 'blur'
                  }"
            >
              <el-input v-model="domain.num" @input="checkNum"></el-input>
            </el-form-item>

            <el-form-item
                :label="'描述'"
            >
              <el-input v-model="domain.intro"></el-input>
            </el-form-item>
          </div>
          <el-button @click.prevent="removeDomain(index)">删除</el-button>
        </div>

        <el-form-item v-show="formData.winnings && formData.winnings.length < 6">
          <el-button @click="addDomain">新增奖品</el-button>
        </el-form-item>

      </el-form>
      <div class="flex-c-c">
        <el-button @click="addDialogShow=false">取消</el-button>
        <el-button
            class="save"
            type="primary"
            @click="addConfirm">确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import commTable from "@/mixins/commTable";
import {fetchProList, fetchPrizeSet, fetchWinningList, fetchDealerList} from "@/api/winning.js"
import utils from "@/utils";
import {mapGetters} from "vuex";
import {winningType} from "@/utils/constant";
import {checkPermission} from "@/directives/permission";
import {fetchGetProduce} from "../../api/winning";
import {get} from "@/utils/request";

export default {
  name: "Winning",
  mixins: [commTable],
  components: {
    commonTable,
  },
  data() {
    return {
      dialogTit:'新增奖项',
      produceNum:0,
      inputProduceNum:0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        dealerId:'',
        produceId: '',
      },
      serchParams: {name: '', phone: ''},
      addDialogShow: false,
      formData: {
        produceId: '',
        datetimerange: [],
        startTime: '',
        endTime: '',
        winnings: []
      },
      options: [],
      tableData: [],
      rules: {
        produceId: [
          {required: true, message: '请选择产品', trigger: ['blur', 'change']}
        ],
        startTime: [
          {required: true, message: '请选择时间', trigger: ['blur', 'change']}
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now());
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000));
        },
      },
      tableIndexList: [],
      rowIndexList:[],
      tableDataList:[],
      allData: [],
      mode: 'add',
      userType: '',
      dealerOptions: [],
      orgData:[], // 后台接口返回来的原始数据
    };
  },
  filters:{
    getWinningType(type){
      return winningType[type]
    }
  },
  mounted() {
    // 获取产品列表，不分页
    this.getProList()
    // 表格
    this.userType = JSON.parse(localStorage.getItem('info')).userType
    this.getchDealerList()
    if(this.userType!== 2) {
        this.getWinningList()
    }
    // 获取所有数据 【表格只存的当前页数据，不是全部，添加时候判断有没有设置过这个产品，需要先获取全部数据，再判断有没有设置过】
    this.getWinningAllList()

    // 获取代理商下拉项

    // 产品下拉项
    this.getProList()

  },
  methods: {
    checkPermission,
    checkNum(){
      let allNum = 0;
      this.formData.winnings.forEach(item=>{
        allNum = allNum + +(item.num);
      })
      console.log(allNum)
      this.inputProduceNum = allNum;
      if (this.inputProduceNum > this.produceNum){
        this.$message({
          message: '奖品数量超过单品数量',
          type: 'error'
        });
        return false;
      }
      return true;
    },
    serchquery() {
      this.getWinningList()
    },
    reset() {
      this.queryParam.produceId = ''
      this.queryParam.dealerId = ''
      this.queryParam.pageNum = 1
      this.getWinningList()
    },
    getchDealerList() {
      fetchDealerList().then(res => {
        this.dealerOptions = res.data
        if(this.userType == 2) {
          this.queryParam.dealerId = this.dealerOptions[0].id
          this.getWinningList()
        }
      })
    },
    numberToChinese(number) {
      const chineseNumMap = {
        0: '零',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '七',
        8: '八',
        9: '九'
      };

      const digitMap = ['', '十', '百', '千', '万', '亿'];

      const numberArr = number.toString().split('').reverse();

      let chineseStr = '';
      for (let i = 0; i < numberArr.length; i++) {
        const digit = numberArr[i];
        let digitStr = chineseNumMap[digit];
        if (i === 1 && digit === '1') {
          digitStr = '';
        }
        chineseStr = digitStr + digitMap[i] + chineseStr;
      }

      return chineseStr;
    },
    getProList() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId
      }
      fetchProList(params).then(res => {
        this.options = res.data
      })
    },
    getWinningAllList() {
        fetchWinningList({pageNum: 1, pageSize: 999,}).then(res => {
          if(res.data.list) {
            this.allData = res.data.list
            console.log('allData',this.allData)
          }
        })
    },
    getWinningList() {
      let params = {
        ...this.queryParam
      }
      const arr=[]
      const indexArr=[]
      this.tableData = []
      get('/winning',params).then(res => {
        this.orgData = res.list
        if (res.list && res.list.length > 0){
          res.list.forEach(item => {
            item.winningDtos.forEach(i => {
              Object.assign(i,item)
              delete i.winningDtos
              arr.push(i)
            })
            indexArr.push({dealerId:item.dealerId,produceId: item.produceId, size: item.winningDtos.length})
          })
          arr.forEach((item,index) => {
            this.$set(this.tableData, index, item);
          });
          this.tableIndexList = indexArr
          this.rowIndexList=[]
          console.log("结果",this.tableIndexList)
        }else {
          this.tableData = []
          this.tableIndexList = []
          this.rowIndexList=[]
        }
        this.total = res.total

        console.log('-------',this.tableData)
        // this.$forceUpdate()
        // this.$nextTick(() => {
        // if (this.$refs.myTable && this.$refs.myTable.doLayout) {
        //         this.$refs.myTable.doLayout();
        //       }
        // })
        // this.tableData = res.data.list
      })
    },
    handleSizeChange(num) {
      this.queryParam.size = num
      this.queryParam.pageNum = 1
      this.getWinningList()
    },
    handleCurrentChange(num) {
      this.queryParam.pageNum = num
      this.getWinningList()
    },
    add() {
      this.dialogTit = '新增奖项'
      this.addDialogShow = true
      this.mode='add'
      this.formData = {
        produceId: '',
        datetimerange: [],
        startTime: '',
        endTime: '',
        winnings: []
      }
      this.$refs.form.resetFields();

    },
    edit(row) {
      this.mode='edit'
      let oneData = this.orgData.filter(item => item.produceId === row.produceId)[0].winningDtos
      this.getProduce(row.produceId)
      this.formData = {
        produceId: row.produceId,
        datetimerange: [row.startTime ? row.startTime : '', row.endTime ? row.endTime : ''],
        startTime: row.startTime,
        endTime: row.endTime,
        winnings: oneData.length ? oneData.map(item => {
          return {
            ...item,
            key: item.id,
          }
        }): []
        // winnings: row.winningDtos.map((item, index) => {
        //   // delete item.winningDtos
        //   return {
        //     ...item,
        //     key: item.id,
        //     ranking: index + 1,
        //   }
        // })
      }
      this.dialogTit = '编辑奖项'
      this.addDialogShow = true
    },
    del(row) {
      console.log('row',row)
    },
    dateChange() {
      this.formData.startTime = this.formData.datetimerange[0]
      this.formData.endTime = this.formData.datetimerange[0]
      this.$refs.form.validateField('startTime')
    },
    addConfirm() {
      if (this.formData.winnings.length === 0) {
        this.$message({
          message: '请添加奖品',
          type: 'error'
        });
        return
      }
      let b = this.checkNum();
      if (!b){
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            produceId: this.formData.produceId,
            winnings: this.formData.winnings,
            startTime: this.formData.datetimerange.length ? this.formData.datetimerange[0] : '',
            endTime: this.formData.datetimerange.length ? this.formData.datetimerange[1] : '',
          }
          params.winnings.forEach((item,index) => {
            delete params.winnings[index].winningDtos
          })
          fetchPrizeSet(params).then(res => {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.addDialogShow = false
            // this.getWinningList()
            window.location.reload()
          })
        }
      })

    },
    addDomain() {
      if(!this.formData.produceId) {
        this.$message({
            message: '请先选择产品',
            type: 'error'
          });
        return false
      }
      if (this.inputProduceNum > this.produceNum){
        this.$message({
          message: '奖品数量超过单品数量',
          type: 'error'
        });
        return false;
      }
      if(this.mode === 'add') {
        if(this.check()) {
          this.formData.winnings.push({
            num: '',
            prize: '',
            intro: '',
            key: Date.now(),
            ranking: this.formData.winnings.length + 1,
          });
        }
      }else {
        this.formData.winnings.push({
          num: '',
          prize: '',
          intro: '',
          key: Date.now(),
          ranking: this.formData.winnings.length + 1,
        });
      }
    },
    getProduce(produceId){
      fetchGetProduce({produceId:produceId}).then(res=>{
        if (res.data){
          this.produceNum = res.data.ratio
          this.checkNum()
        }
      })
    },
    check() {
      // 判断有没有设置奖项
      if(this.formData.produceId) {
        this.getProduce(this.formData.produceId)
        console.log('this.formData.produceId', this.formData.produceId)
        console.log('222', this.allData)
        if(this.allData.length) {
          let item = this.allData.filter(item => item.produceId == this.formData.produceId)[0]
          console.log('444', item)
          if(item && item.winningDtos.length > 0) {
            console.log('555', item.winningDtos)
            this.formData.winnings = []
            this.$message({
              message: '产品已经设置过当前产品奖项，请编辑',
              type: 'error'
            });
            return false
          }else {
            return true
          }
        }else {
          return true
        }
      }else {
        return true
      }
    },
    removeDomain(index) {
      this.formData.winnings.splice(index, 1)
      this.formData.winnings.forEach((item, iIndex) => {
        item.ranking = iIndex + 1
      })
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      let indexList = []
      if (this.userType == 2){
        indexList.push(3)
        indexList.push(8)
      }else {
        indexList.push(2)
        indexList.push(7)
      }
      if (columnIndex <= indexList[0] || columnIndex === indexList[1]) {
        let number = this.rowIndexList.findIndex(val=>{
          return val.index==rowIndex
        });
        if (number != -1){
          return {
            rowspan: this.rowIndexList[number].size,
            colspan: 1
          };
        }
        let index = this.tableIndexList.findIndex(val => {
          return  val.produceId == row.produceId&&val.dealerId==row.dealerId
        });
        if (index != -1) {
          let obj = this.tableIndexList[index];
          this.tableIndexList.splice(index,1)
          this.rowIndexList.push({index:rowIndex,size:obj.size})
          return {
            rowspan: obj.size,
            colspan: 1
          };
        }else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.demo-table-expand {
  font-size: 0;
  border-bottom: 1px solid #eee;
  padding-left: 20px;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}

.domainBox {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.domainLeft {
  width: 70%;
  margin-right: 10%;
}
</style>
