import httpUtil from "@/utils/httpUtil";
/** 获取产品列表信息 */
export function fetchProList(params) {
	return httpUtil.get("/winning/getProduceList", params)
}

/***添加常用语 */
export function fetchPrizeSet(params) {
	return httpUtil.post("/winning", params)
}

/**奖项设置列表 */
export function fetchWinningList(params) {
	return httpUtil.get("/winning", params)
}
export function fetchGetProduce(params) {
	return httpUtil.get("/winning/getProduce", params)
}
/**中奖记录**/
export function fetchWinUserList(params) {
	return httpUtil.get("/winning/winningLog", params)
}

/***代理商、经销商不分页**/
export function fetchDealerList(params) {
	return httpUtil.get("/dealer/newNoPage", params)
}
